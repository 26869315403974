@font-face {
  font-family: Lato Regular;
  src: url("../Fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: Lato Bllack;
  src: url("../Fonts/Lato-Black.ttf");
}

@font-face {
  font-family: Lato Bold;
  src: url("../Fonts/Lato-Bold.ttf");
}

@font-face {
  font-family: Lato Heavy;
  src: url("../Fonts/Lato-Heavy.ttf");
}

@font-face {
  font-family: Lato Semibold;
  src: url("../Fonts/Lato-Semibold.ttf");
}

.empty {
  height: 86px;
  width: 100%;
  background-color: whitesmoke;
  position: relative;
}

.application-form-container {
  width: 58%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.app-heading {
  font-family: Lato Bold;
  font-size: 17px;
}

.app-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.choose-service {
  display: flex;
  flex-direction: row;
  /* gap: 70px; */
  justify-content: space-around;
  width: 100%;
}

.service-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.upload-photo {
  position: absolute;
  right: 100px;
  top: 140px;
  width: 130px;
  height: 140px;
}

.upload-input {
  padding: 10px;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-input span {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #e1e1e1;
  border-radius: 7px;
  font-size: 14px;
}

.upload-input input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.main-app-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}

.require {
  color: #000080;
  font-family: Lato Semibold;
  font-size: 15px;
}

.customer-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  width: 100%;
  font-family: Lato Regular;
  font-size: 14px;
}

.info1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.company {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.company input {
  width: 100px;
  height: 23px;
  outline: none;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 0px 5px;
  font-size: 14px;
  font-family: Lato Regular;
}

.choose-gender {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.info2 {
  align-items: flex-start;
}

.customer-deets {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
}

.customer-deets input {
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  outline: none;
  margin-left: 20px;
}

.info3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
}

/* .info3 input {
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  outline: none;
  margin-left: 10px;
} */

.info4 {
  width: 100%;
  text-align: left;
}

.multiline-input {
  border: none;
  border-bottom: 1px solid black; /* Set bottom border */
  resize: none;
  width: 100%;
  height: 30px;
  outline: none;
  margin-left: 10px;
}

.inputField {
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  outline: none;
  margin-left: 10px;
}

.pin-class {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 13px;
}

.pin-class span input {
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  outline: none;
}

.per-info input {
  width: 130px;
}

.info5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 10px;
}

.email {
  width: 300px;
}

.email input {
  width: 100%;
}

.submit-app-form {
  background: #469242 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  opacity: 1;
  border: none;
  width: 100px;
  color: white;
  font-family: Lato Bold;
}

.per-info select {
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  padding: 5px;
  font-size: 13px;
  outline: none;
  font-family: Lato Regular;
  letter-spacing: 0.8px;
  /* width: 100%; */
}
