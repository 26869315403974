@font-face {
  font-family: Lato Regular;
  src: url("./Fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: Lato Semibold;
  src: url("./Fonts/Lato-Semibold.ttf");
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.navbar1 {
  background: white 0% 0% no-repeat padding-box;
  position: fixed;
  top: 0;
  height: 85px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: 0.98;
}

.nav1 {
  position: fixed;
  /* top: 0; */
  /* left: 0; */
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* .navbar1::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 13%;
  width: 100%;
  background-color: #ffffff;
  opacity: 0.99;
  z-index: -1;
} */

.icon {
  width: 100px;
  margin-left: 20px;
}

.nav1-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  /* gap: 5px; */
  /* margin-bottom: 15px; */
  margin-right: 25px;
}

.newsroom ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.newsroom ul li {
  list-style: none;
  font-family: Lato Regular;
  font-size: 13px;
}

.newsroom ul li a:hover {
  color: #70cd68;
}

.nav1-list ul li a:hover {
  color: #70cd68;
}

.nav1-list ul li {
  list-style: none;
  font-family: Lato Semibold;
  color: #162c68;
  font-size: 16px;
}

.newsroom ul li a {
  text-decoration: none;
  color: black;
}

.nav1-list ul li a {
  text-decoration: none;
  color: #162c68;
  font-size: 16px;
}

.nav1-list ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 0px;
}

.hr {
  width: 2px;
  height: 17px;
  background-color: black;
}

.digital-btn {
  background-color: white;
  border: 1px solid #227e1d;
  border-radius: 5px;
  width: 80px;
  padding: 4px 15px;
  font-family: Lato Semibold;
  font-size: 16px;
  text-align: center;
  color: #162c68;
  cursor: pointer;
}

.my-dropdown .dropdown-toggle::after {
  background-color: transparent !important; /* Use !important to override Bootstrap styles */
}

.my-dropdown .dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  color: #162c68 !important;
  margin: 0px !important;
  padding: 0px !important;
}

.my-dropdown .dropdown-toggle:hover {
  color: #70cd68 !important;
}

.service-icons {
  width: 100%;
  /* height: 30px; */
}

.icon2 {
  height: 25px;
  /* width: 80%; */
}

.dropdown-item {
  --bs-dropdown-item-padding-y: 0.8rem;
}

.homescreen {
  position: relative;
}

.nav1-container {
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
}

.partner-wait {
  display: flex !important;
  flex-direction: column !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .nav1 {
    width: 100%;
    /* justify-content: center;
    gap: 20px; */
    /* top: 0; */
  }

  .navbar1 {
    height: 65px;
  }

  .icon {
    width: 62px;
  }

  .newsroom ul {
    margin-bottom: 7px;
  }

  .newsroom ul li {
    font-size: 10px;
  }

  .nav1-list ul {
    gap: 25px;
  }

  .nav1-list ul li a {
    font-size: 12px;
  }

  .dropdown-menu {
    --bs-dropdown-min-width: 7rem !important;
    --bs-dropdown-padding-x: 0.3rem !important;
  }

  .my-dropdown .dropdown-toggle {
    font-size: 12px;
  }

  .dropdown-item {
    --bs-dropdown-item-padding-y: 0.6rem !important;
    --bs-dropdown-item-padding-x: 0.3rem !important;
  }
}

@media only screen and (min-width: 1024px) {
  .navbar1 {
    height: 82px;
  }

  .nav1 {
    height: 82px;
  }

  .icon {
    width: 83px;
  }

  .newsroom ul li {
    font-size: 11px;
  }

  .nav1-list ul li a {
    font-size: 16px;
  }

  .my-dropdown .dropdown-toggle {
    font-size: 16px;
  }

  .nav1-right {
    gap: 6px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .hr {
    width: 1px;
    height: 11px;
  }

  /* .nav1 {
    top: 10px;
    width: 100vw;
    /* justify-content: center; */
  /* gap: 20px; 
  } */

  .nav1-right {
    margin-right: 7px;
  }

  .navbar1 {
    height: 65px;
  }
  .nav1 {
    height: 65px;
    top: 0;
    width: 100vw;
  }

  .icon {
    width: 52px;
  }

  .newsroom ul {
    margin-bottom: 7px;
  }

  .newsroom ul li {
    font-size: 9px;
  }

  .nav1-list ul {
    gap: 20px;
  }

  .nav1-list ul li a {
    font-size: 10px;
  }

  .my-dropdown .dropdown-toggle {
    font-size: 10px;
    height: 10px;
  }

  .caption-left h3 {
    font-size: 13px !important;
  }

  .caption-left {
    width: 240px !important;
    top: 53% !important;
  }

  .caption-left button {
    margin-top: 15px !important;
    height: 17px !important;
    padding: 2px 6px !important;
    font-size: 9px !important;
    border-radius: 4px !important;
    gap: 6px;
  }

  .caption-left button svg {
    height: 10px !important;
    width: 10px !important;
  }

  .carousel {
    height: 31vh !important;
  }

  .carousel-item {
    height: 100%;
  }

  .carousel-indicators [data-bs-target] {
    width: 20px !important;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 1rem !important;
  }

  .dropdown-item {
    --bs-dropdown-item-padding-y: 0.3rem !important;
    --bs-dropdown-item-padding-x: 0.1rem !important;
  }

  .dropdown-menu {
    --bs-dropdown-min-width: 5rem !important;
    --bs-dropdown-padding-x: 0.2rem !important;
  }

  .service-icons {
    height: 20px;
  }

  .icon2 {
    height: 17px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 390px) {
  .navbar1 {
    height: 45px;
  }

  .nav1 {
    height: 45px;
    top: 0;
  }

  .nav1-list {
    height: 23px;
  }

  .nav1-right {
    margin-right: 10px;
  }

  .newsroom ul {
    margin-bottom: 0px;
    padding-left: 0px;
  }

  .newsroom ul li a {
    font-size: 8px;
  }

  .nav1-list ul li a {
    font-size: 8px;
  }

  .nav1-list ul {
    gap: 10px;
  }

  .my-dropdown .dropdown-toggle {
    font-size: 8px;
    height: 8px;
  }
}

@media screen and (min-width: 1920px) {
  .navbar1 {
    height: 143px;
  }

  .icon {
    width: 145px;
    margin-left: 40px;
  }

  .newsroom ul {
    gap: 9px;
    font-size: 17px;
  }

  .newsroom ul li {
    font-size: 20px;
  }

  .nav1-list ul li a {
    font-size: 30px;
  }

  .my-dropdown .dropdown-toggle {
    font-size: 30px;
  }

  .nav1-right {
    gap: 11px;
  }

  .dropdown-menu {
    --bs-dropdown-min-width: 17rem !important;
    --bs-dropdown-item-padding-y: 17px !important;
    --bs-dropdown-item-padding-x: 18px !important;
  }

  .icon2 {
    height: 38px;
  }
}
