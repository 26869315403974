body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#google_translate_element {
  position: fixed !important;
  bottom: 10px !important;
  right: 150px !important;
  height: 30px !important;
  width: 30px !important;
  z-index: 5 !important;
  outline: none !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
  z-index: unset;
}

.VIpgJd-ZVi9od-l4eHX-hSRGPd,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:link,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:visited,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:hover,
.VIpgJd-ZVi9od-l4eHX-hSRGPd:active {
  display: none;
}

html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
}
