@font-face {
  font-family: Lato Regular;
  src: url("./Fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: Lato Bllack;
  src: url("./Fonts/Lato-Black.ttf");
}

@font-face {
  font-family: Lato Bold;
  src: url("./Fonts/Lato-Bold.ttf");
}

@font-face {
  font-family: Lato Heavy;
  src: url("./Fonts/Lato-Heavy.ttf");
}

.carousel {
  /* height: 640px; */
  height: 100vh;
  position: relative;
}

.carousel-inner {
  height: 100%;
}

.carousel-inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0d3b5a;
  opacity: 0.55;
}

.caption-left {
  position: absolute;
  top: 50%;
  transform: translate(-20%, -50%);
  color: white;
  padding: 20px;
  width: 450px;
  font-family: Lato Bllack;
  letter-spacing: 1px;
  /* position: relative; */
}

.caption-left h3 {
  text-align: left;
  font-size: 37px;
  font-size: 2.3rem;
  position: relative;
  left: -150%;
  animation: slideInLeft 1.5s ease forwards;
}

@keyframes slideInLeft {
  from {
    left: -150%;
  }
  to {
    left: 0;
  }
}

.carousel-caption {
  text-align: left;
}

.caption-left button {
  margin-top: 40px;
  border: none;
  background: #67e25d 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
  text-align: left;
  color: #142a68;
  font-family: Lato Bold;
  height: 40px;
  padding: 5px 14px;
  /* width: 90px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
  right: -100%;
  animation: slideInRight 1.5s ease forwards;
}

@keyframes slideInRight {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}

.aboutUs-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 550px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.aboutUs-container .about-svg1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 290px;
}

.aboutUs-container .about-svg2 {
  position: absolute;
  right: -150px;
  bottom: 0;
  width: 350px;
}

.aboutUs-left {
  height: 100%;
  width: 85%;
  /* padding: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.aboutUs-left img {
  width: 370px;
}

.aboutUs-right {
  width: 90%;
  padding-right: 107px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  height: 100%;
}

.aboutUs-right span {
  font-family: Lato Bllack;
  color: #162c68;
  letter-spacing: 0.7px;
}

.aboutUs-right div {
  font-family: Lato Regular;
  font-size: 15px;
  color: #040404;
  letter-spacing: 0.8px;
}

.green-box {
  position: relative;
  width: 100%;
}

.green {
  position: relative;
  border: 2.7px solid #70cd68;
  border-radius: 15px;
  width: 340px;
  height: 54%;
  /* z-index: -1; */
}

.green img {
  position: absolute;
  right: 35px;
  bottom: 30px;
}

.missionImg {
  background-image: url("./Assets/missionImg.png"); /* Set background image */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 640px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.mission-left {
  padding: 0px 40px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 60px;
  height: 100%;
  background: white;
  opacity: 0.87;
}

.missionClass {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.missionClass span {
  font-family: Lato Bllack;
  color: #162c68;
  letter-spacing: 0.7px;
}

.missionClass div {
  font-family: Lato Regular;
  font-size: 15px;
  color: black;
  letter-spacing: 0.8px;
}

.mission-right {
  width: 70%;
}

.serves-container {
  height: 100%;
  width: 100%;
}

.serves {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 45px;
  background-image: linear-gradient(to bottom, white 90%, #efefef 10%);
  padding-top: 66px;
  position: relative;
}

.serves img {
  width: 22%;
}

.serves video {
  max-width: 75%;
  z-index: 1;
}

.serves2 {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  gap: 70px;
}

.serves-detail {
  text-align: justify;
  width: 75%;
  font-family: Lato Bold;
  letter-spacing: 0.8px;
  font-size: 13px;
  line-height: 22px;
}

.serves-highlight {
  color: #f85e07;
  font-family: Lato Bllack;
}

.serves-bold {
  font-family: Lato Heavy;
  font-weight: bold;
  color: black;
}

.what-we-do-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.what-we-do-container span {
  font-family: Lato Bllack;
  color: #162c68;
  font-size: 17px;
}

.serves-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 85%;
  padding-bottom: 50px;
}

.serveBox {
  background-color: white;
  border-radius: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 23px 15px 20px 15px;
}

.serveBox img {
  width: 75%;
}

.serveBox div {
  font-family: Lato Regular;
  font-size: 14px;
  width: 100%;
}

.serves-orange {
  background-color: #fcb48b;
  height: 320px;
  width: 100%;
  position: absolute;
  bottom: 19px;
  z-index: 0;
}

.serves-orange::before,
.serves-orange::after {
  content: "";
  position: absolute;
  background-color: inherit;
  width: 50%;
  height: 100%;
}

.serves-orange::before {
  top: 0;
  left: 0;
  transform-origin: bottom left;
  transform: skewY(-9deg);
}

.serves-orange::after {
  top: 0;
  right: 0;
  transform-origin: bottom right;
  transform: skewY(9deg); /* Adjust the angle as needed */
}

.serves-orange .orange-left-svg {
  position: absolute;
  left: -20px;
  bottom: -21px;
  height: 100%;
  width: 280px;
}

.serves-orange .orange-right-svg {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 38%;
  width: 170px;
  z-index: 2;
}

.drives-green {
  background-color: #3ec26e;
  height: 320px;
  width: 100%;
  position: absolute;
  bottom: 19px;
  z-index: 0;
}

.drives-green::before,
.drives-green::after {
  content: "";
  position: absolute;
  background-color: inherit;
  width: 50%;
  height: 100%;
}

.drives-green::before {
  top: 0;
  left: 0;
  transform-origin: bottom left;
  transform: skewY(-9deg);
}

.drives-green::after {
  top: 0;
  right: 0;
  transform-origin: bottom right;
  transform: skewY(9deg); /* Adjust the angle as needed */
}

.drives-green .green-left-svg {
  position: absolute;
  left: 0;
  bottom: -21px;
  height: 100%;
  width: 280px;
}

.drives-green .green-right-svg {
  position: absolute;
  right: 70px;
  bottom: 25px;
  width: 360px;
  z-index: -1;
}

.drives-green .green-left-2 {
  position: absolute;
  left: -40px;
  top: -199px;
  height: 85%;
  width: 260px;
  z-index: -1;
}

.drives-highlight {
  color: #227e1d;
  font-family: Lato Bllack;
}

.drives2 {
  position: relative;
}

.what-we-do-container .drives-svg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 550px;
  width: 450px;
  z-index: 0;
}

.drives-block {
  z-index: 1;
}

.myHome-blue {
  background-color: #b7adf8;
}

.myHome-highlight {
  color: #22156b;
  font-family: Lato Bllack;
}

.myHome-svg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 610px;
  width: 230px;
  z-index: 0;
}

.block {
  height: 380px;
}

.block1 {
  height: 350px;
}

.contact-main {
  position: relative;
}

.contact-main img {
  width: 100%;
}

.contact-opaque {
  position: absolute;
  top: 0;
  background-color: #11344b;
  opacity: 0.85;
  width: 100%;
  height: 100%;
}

.contact-us {
  color: white;
  font-family: Lato Heavy;
  font-size: 40px;
  position: absolute;
  bottom: 80px;
  width: 100%;
}

.contact-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 70px 90px;
}

.contact-details-left {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.location {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.contact-svg {
  height: 20px;
  width: 20px;
}

.land-location-svg {
  height: 20px;
  width: 20px;
}

.location-name {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 65%;
}

.location-name span {
  text-align: left;
}

.left-head {
  font-family: Lato Bllack;
  color: black;
  font-size: 20px;
  letter-spacing: 0.9px;
}

.left-text {
  font-family: Lato Regular;
  font-size: 13px;
  width: 60%;
  text-align: left;
}

.contact-head {
  font-family: Lato Bllack;
  font-size: 14px;
}

.contact-head-detail {
  font-family: Lato Regular;
  font-size: 13px;
}

.contact-details-right {
  width: 60%;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}

.form-group input {
  width: 100%;
}

.input-field {
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  padding: 5px 10px;
  font-size: 15px;
  outline: none;
  font-family: Lato Regular;
  letter-spacing: 0.8px;
  width: 100%;
}

.input-field::placeholder {
  color: black;
  font-family: Lato Regular;
  letter-spacing: 0.8px;
}

textarea {
  height: 100px;
}

.contact-form button {
  background: #227e1d 0% 0% no-repeat padding-box;
  border-radius: 7px;
  border: none;
  padding: 7px 16px;
  color: white;
  font-family: Lato Bold;
  font-size: 15px;
  text-align: center;
  letter-spacing: 0.5px;
}

.footer {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 50px;
  font-family: Lato Bold;
  font-size: 13px;
}

.modal-content {
  width: 450px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  height: 200px;
  position: relative;
  /* width: 200px; */
  overflow: hidden;
}

.modal-svg-left {
  position: absolute;
  bottom: 0;
  width: 140px;
  left: -10px;
}

.modal-right-svg {
  position: absolute;
  top: 10px;
  width: 140px;
  right: -50px;
}

.modal-body span {
  margin-bottom: 20px;
  font-size: 18px;
}

.language-drawer {
  position: fixed;
  right: 55px;
  top: 39px;
  width: 290px;
  height: auto;
  background-color: white;
  z-index: 2;
  border: 1px solid grey;
}

.language-container {
  padding: 20px 30px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
}

.language-left label {
  text-align: left;
  font-family: Lato Bold;
  font-size: 15px;
  width: 100%;
  /* margin-bottom: 3px; */
}

.language-left ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3px;
  font-family: Lato Regular;
  font-size: 13px;
  padding: 0px;
}

.indian-language {
  cursor: pointer;
}

.indian-language:hover {
  color: orange;
}

.language-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3px;
}

.language-right label {
  text-align: left;
  font-family: Lato Bold;
  font-size: 14px;
  width: 100%;
}

.language-right span {
  font-family: Lato Regular;
  font-size: 13px;
  cursor: pointer;
}

.language-right span:hover {
  color: orange;
}

.carousel-control-next,
.carousel-control-prev {
  width: 7% !important;
}

.btn-popover:hover {
  background-color: #67e25d !important;
  color: #142a68 !important;
}

.custom-popover .popover-header {
  background-color: rgb(213, 255, 213) !important;
  border: none;
  margin: 0px;
}

.custom-popover .popover-body {
  background-color: rgb(213, 255, 213) !important;
  font-size: 16px !important;
  border: none;
  border-radius: 10px;
  font-family: Lato Bllack;
  color: black;
  margin: 0px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .carousel {
    height: 47vh;
  }

  .caption-left h3 {
    font-size: 25px;
  }

  .caption-left button {
    margin-top: 27px;
    height: 34px;
    padding: 5px 9px;
    /* font-size: 14px; */
  }

  .caption-left {
    width: 400px;
    top: 55%;
  }

  .carousel-item {
    height: 100%;
  }

  .carousel-caption {
    left: 18% !important;
  }

  .aboutUs-container .about-svg1 {
    width: 172px;
  }

  .aboutUs-container {
    height: 376px;
  }

  .aboutUs-left img {
    width: 266px;
  }

  .aboutUs-right {
    height: 100%;
    padding: 0px 40px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  .aboutUs-right div {
    font-size: 11px;
  }

  .aboutUs-right span {
    font-size: 13px;
  }

  .aboutUs-container .about-svg2 {
    right: -95px;
    width: 186px;
  }

  .missionImg {
    height: 449px;
  }

  .mission-left {
    gap: 22px;
  }

  .missionClass {
    gap: 5px;
  }

  .missionClass span {
    font-size: 13px;
  }

  .missionClass div {
    font-size: 11px;
  }

  .serves {
    padding-top: 55px;
    gap: 33px;
  }

  .serves img {
    width: 20%;
  }

  .serves video {
    max-width: 80%;
  }

  .serves-orange {
    /* bottom: 18px; */
    height: 185px;
  }

  .serves-orange .orange-left-svg {
    left: -15px;
    bottom: -13px;
    width: 159px;
  }

  .serves-orange .orange-right-svg {
    width: 76px;
  }

  .serves-detail {
    width: 83%;
    font-size: 10px;
    letter-spacing: 0.6px;
  }

  .serves2 {
    gap: 40px;
  }

  .what-we-do-container span {
    font-size: 14px;
  }

  .what-we-do-container {
    gap: 12px;
  }

  .serveBox div {
    font-size: 10px;
  }

  .serveBox img {
    width: 90%;
  }

  .serveBox {
    gap: 10px;
    padding: 15px 9px 7px 11px;
  }

  .drives-green {
    height: 185px;
  }

  .drives-green .green-left-2 {
    top: -110px;
    height: 82%;
    width: 161px;
  }

  .drives-green .green-right-svg {
    right: 23px;
    bottom: 25px;
    width: 207px;
  }

  .drives-green .green-left-svg {
    bottom: -29px;
    height: 100%;
    width: 180px;
  }

  .block1 {
    height: 227px;
  }

  .what-we-do-container .drives-svg {
    height: 414px;
    width: 325px;
  }

  .block {
    height: 269px;
  }

  .myHome-svg {
    height: 440px;
    width: 160px;
  }

  .contact-details {
    padding: 59px 40px;
    gap: 30px;
  }

  .contact-details-left {
    gap: 16px;
  }

  .left-head {
    font-size: 15px;
  }

  .left-text {
    font-size: 10px;
    width: 65%;
  }

  .contact-head {
    font-size: 11px;
  }

  .contact-head-detail {
    font-size: 11px;
  }

  .location-name {
    width: 85%;
  }

  .land-location-svg {
    height: 18px;
    width: 18px;
  }

  .location {
    gap: 15px;
  }

  .contact-svg {
    height: 18px;
    width: 18px;
  }

  .input-field {
    font-size: 11px;
    letter-spacing: 0.5px;
    padding: 4px 7px;
  }

  .contact-details-right {
    width: 50%;
  }

  .contact-form button {
    padding: 2px 9px;
    border-radius: 5px;
    font-size: 12px;
  }

  .language-left label {
    font-size: 13px;
  }

  .language-left ul {
    font-size: 11px;
  }

  .language-right label {
    font-size: 13px;
  }

  .language-right span {
    font-size: 11px;
  }

  .language-drawer {
    width: 235px;
    top: 31px;
  }
  .language-container {
    padding: 9px 17px;
  }

  .custom-popover .popover-body {
    font-size: 12px !important;
    --bs-popover-body-padding-x: 10px;
    --bs-popover-body-padding-y: 6px;
    --bs-popover-arrow-width: 15px;
  }
}
@media only screen and (min-width: 1024px) {
  .block1 {
    height: 267px;
  }

  .serveBox {
    padding: 15px 11px 7px 11px;
  }

  .block {
    height: 301px;
  }

  .caption-left h3 {
    font-size: 30px;
  }

  .caption-left {
    width: 462px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .aboutUs-container .about-svg1 {
    width: 94px;
  }

  .aboutUs-container {
    height: 274px;
  }

  .aboutUs-left img {
    width: 162px;
  }

  .aboutUs-container .about-svg2 {
    right: -68px;
    width: 146px;
  }

  .aboutUs-right div {
    font-size: 6px;
  }

  .aboutUs-right {
    padding-right: 27px;
    gap: 10px;
  }

  .aboutUs-right span {
    font-size: 9px;
  }

  .missionImg {
    height: 282px;
  }

  .missionClass span {
    font-size: 9px;
  }

  .missionClass div {
    font-size: 6px;
  }

  .missionClass {
    gap: 1px;
  }

  .mission-left {
    padding: 0px 17px;
    gap: 23px;
  }

  .serves video {
    max-width: 82%;
  }

  .serves-orange {
    height: 91px;
  }

  .serves {
    gap: 26px;
    padding-top: 35px;
  }

  .serves img {
    width: 24%;
  }

  .serves-orange .orange-left-svg {
    left: -79px;
    bottom: 0px;
    height: 95%;
    width: 230px;
  }

  .serves-orange .orange-right-svg {
    width: 45px;
  }

  .serves-detail {
    letter-spacing: 0.5px;
    font-size: 6px;
    line-height: 11px;
    width: 83%;
  }

  .serves2 {
    padding-top: 22px;
    gap: 35px;
  }

  .what-we-do-container span {
    font-size: 10px;
  }

  .serveBox div {
    font-size: 7px;
  }

  .serves-block {
    gap: 19px;
    width: 90%;
  }

  /* .serveBox img {
    width: 82%;
  } */

  .serveBox {
    padding: 10px 8px 10px;
    gap: 9px;
  }

  .drives-green {
    height: 95px;
  }

  .drives-green .green-left-2 {
    top: -68px;
    width: 126px;
  }

  .drives-green .green-right-svg {
    right: 0px;
    bottom: 15px;
    width: 132px;
  }

  .drives-green .green-left-svg {
    bottom: -16px;
    height: 104%;
    width: 65px;
  }

  .block1 {
    height: 142px;
  }

  .what-we-do-container .drives-svg {
    height: 290px;
    width: 231px;
  }

  .what-we-do-container {
    gap: 19px;
  }

  .block {
    height: 172px;
  }

  .myHome-svg {
    height: 312px;
    width: 106px;
  }

  .contact-main img {
    height: 145px;
  }

  .contact-us {
    font-size: 24px;
    bottom: 44px;
  }

  .contact-details {
    padding: 40px 24px;
    gap: 30px;
  }

  .location-name {
    width: 100%;
  }

  .contact-details-left {
    gap: 9px;
  }

  .left-head {
    font-size: 10px;
  }

  .left-text {
    font-size: 6px;
    width: 76%;
  }

  .contact-head {
    font-size: 9px;
  }

  .contact-head-detail {
    font-size: 9px;
  }

  .contact-info {
    gap: 10px;
  }

  .location {
    gap: 13px;
  }

  .land-location-svg {
    height: 14px;
    width: 14px;
  }

  .contact-svg {
    height: 14px;
    width: 14px;
  }

  .input-field {
    border-radius: 3px;
    padding: 0px 3px;
    font-size: 9px;
  }

  .form-group {
    gap: 16px;
  }

  .contact-form {
    gap: 11px;
  }

  .contact-details-right {
    width: 45%;
  }

  .contact-form button {
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 10px;
  }

  .footer {
    padding: 0px 18px;
    height: 25px;
    font-size: 9px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 380px) {
  .carousel {
    height: 35vh !important;
  }

  .carousel-indicators [data-bs-target] {
    height: 2px !important;
    width: 16px !important;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 13px !important;
  }

  .carousel-caption {
    left: 19% !important;
    bottom: 0.28rem !important;
  }

  .caption-left button {
    gap: 5px;
    padding: 2px 5px !important;
    font-size: 7px !important;
  }

  .caption-left button svg {
    height: 8px !important;
    width: 8px !important;
  }

  .custom-popover .popover-body {
    font-size: 6px !important;
    --bs-popover-body-padding-y: 4px;
    --bs-popover-body-padding-x: 8px;
    border-radius: 4px !important;
  }

  .popover {
    --bs-popover-arrow-width: 13px !important;
    --bs-popover-arrow-height: 6px !important;
  }

  .aboutUs-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 11px;
  }

  .aboutUs-left img {
    width: 115px;
  }

  .aboutUs-right {
    width: 100%;
    padding: 0px;
    gap: 5px;
    align-items: center;
  }

  .aboutUs-right div {
    text-align: center;
    letter-spacing: 0.3px;
  }

  .missionImg {
    height: 223px;
  }

  .mission-right {
    width: 29%;
  }

  .mission-left {
    padding: 0px 14px;
    gap: 14px;
  }

  .serves img {
    width: 30%;
  }

  .serves-orange {
    bottom: 7px;
    height: 72px;
  }

  .missionClass div {
    letter-spacing: 0.3px;
  }

  .serves-detail {
    letter-spacing: 0.3px;
    width: 89%;
  }

  .serves-block {
    gap: 13px;
    padding-bottom: 35px;
  }

  .serveBox {
    padding: 8px 7px 7px;
    gap: 6px;
    border-radius: 10px;
  }

  .serveBox img {
    width: 100%;
  }

  .serveBox div {
    font-size: 6px;
  }

  .drives-green {
    height: 72px;
    bottom: 7px;
  }

  .drives-green .green-left-2 {
    top: -44px;
    width: 111px;
  }

  .drives-green .green-right-svg {
    bottom: -9px;
    width: 105px;
  }

  .myHome-svg {
    height: 296px;
    width: 98px;
  }

  .left-head {
    letter-spacing: 0.6px;
  }

  .contact-details {
    padding: 40px 15px;
    flex-direction: column;
  }

  .contact-details-left {
    width: 100%;
  }

  .contact-details-right {
    width: 100%;
  }

  .contact-form {
    align-items: center;
    font-size: 6px;
  }

  .input-field {
    font-size: 8px;
  }

  .footer {
    padding: 0px 14px;
    height: 16px;
    font-size: 6px;
  }
}

@media screen and (min-width: 1920px) {
  .carousel-caption {
    bottom: 15.25rem !important;
  }

  .caption-left {
    width: 750px;
  }

  .caption-left h3 {
    font-size: 3.5rem;
  }

  .caption-left button {
    height: 59px;
    padding: 8px 25px;
    font-size: 30px;
    border-radius: 9px;
  }

  .caption-left button svg {
    height: 34px;
    width: 34px;
  }

  .serveBox {
    padding: 31px 15px 20px 15px;
  }

  .block1 {
    height: 445px;
  }

  .block {
    height: 464px;
  }

  .contact-details {
    padding: 82px 137px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 4rem !important;
    height: 4rem !important;
  }

  .aboutUs-container {
    height: 757px;
  }

  .aboutUs-left img {
    width: 535px;
  }

  .aboutUs-container .about-svg1 {
    width: 443px;
  }

  .aboutUs-right span {
    font-size: 27px;
  }

  .aboutUs-right div {
    font-size: 24px;
  }

  .aboutUs-right {
    width: 96%;
    padding-right: 70px;
  }

  .aboutUs-container .about-svg2 {
    right: -235px;
    width: 483px;
  }

  .missionImg {
    height: 87vh !important;
  }

  .mission-right {
    width: 55%;
  }

  .missionClass span {
    font-size: 27px;
  }

  .missionClass div {
    font-size: 24px;
  }

  .serves {
    padding-top: 109px;
    gap: 56px;
  }

  .serves video {
    max-width: 78%;
  }

  .serves-orange {
    height: 434px;
  }

  .serves-orange .orange-left-svg {
    width: 396px;
  }

  .serves-orange .orange-right-svg {
    height: 43%;
    width: 204px;
  }

  .serves-detail {
    width: 82%;
    letter-spacing: 0.8px;
    font-size: 21px;
    line-height: 35px;
  }

  .serves2 {
    gap: 86px;
  }
}
