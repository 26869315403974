@font-face {
  font-family: Lato Regular;
  src: url("../Fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: Lato Bllack;
  src: url("../Fonts/Lato-Black.ttf");
}

@font-face {
  font-family: Lato Bold;
  src: url("../Fonts/Lato-Bold.ttf");
}

@font-face {
  font-family: Lato Heavy;
  src: url("../Fonts/Lato-Heavy.ttf");
}

@font-face {
  font-family: Lato Semibold;
  src: url("../Fonts/Lato-Semibold.ttf");
}

.login-main-container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.login-main-container img {
  width: 8%;
  position: absolute;
  left: 4%;
  top: 4%;
  z-index: 1;
}

.login-svg1 {
  position: absolute;
  left: 0;
  width: 20%;
}

.login-svg2 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 28%;
}

.login-container {
  height: 100%;
  padding: 3%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15%;
}

.login-heading {
  font-family: Lato Bold;
  color: #000080;
  font-size: 130%;
}

.login-box {
  border: 1px solid #469242;
  border-radius: 20px;
  width: 40%;
  height: 75%;
}

.login-form-container {
  height: 100%;
  width: 100%;
  padding: 20px 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15%;
}

.login-form {
  height: 40%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 10%;
}

.input-class {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Lato Regular;
  font-size: 14px;
}

.input-class input {
  width: 100%;
  border: 1px solid #1c6514;
  border-radius: 6px;
  outline: none;
  padding: 4px 9px;
}

.mandatory {
  font-size: 12px;
}

.login-btn {
  background-color: #469242;
  border: 1px solid #707070;
  border-radius: 6px;
  color: white;
  font-family: Lato Regular;
  width: 27%;
}

.login-alternates {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.alternate span {
  font-family: Lato Regular;
  font-size: 14px;
}

.alternate a {
  font-family: Lato Bold;
  font-size: 14px;
  text-decoration: none;
  color: #000080;
  margin-left: 3px;
}

.register-progress {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.register-progress-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.horizontal-line {
  border-top: 2px solid #ccc; /* Adjust color and thickness as needed */
  margin: 0 10px;
  width: 100%;
}

.normal-text {
  font-family: Lato Regular;
  font-size: 14px;
}

.bold-text {
  font-family: Lato Bold;
  font-size: 14px;
}

.stepper-container {
  width: 90%;
  font-family: Lato Bold;
}

.red-asterisk {
  color: red;
}

.register-container {
  gap: 2%;
}

.step1 {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-top: 70px;
  font-family: Lato Regular;
}

.step2 {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 70px;
  font-family: Lato Regular;
}

.step2 .input-class {
  width: 40%;
  justify-content: center;
  align-items: center;
}

.step2 .input-class label {
  text-align: left;
  width: 100%;
}

.step2 span {
  font-size: 14px;
  font-family: Lato Regular;
}

.send-again {
  color: #000080;
  font-family: Lato Bold;
  font-weight: bolder;
}

.next-btns {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.next-btn {
  background: #469242 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  border: none;
  color: white;
  font-family: Lato Regular;
  width: 100%;
  padding: 3px 0px;
}

.cancel-btn {
  background: #bbbbbb 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  border: none;
  color: white;
  font-family: Lato Regular;
  width: 100%;
  padding: 3px 0px;
}

.step2 .next-btns {
  width: 45%;
}

.step3 {
  margin-top: 10px;
  gap: 10px;
  width: 35%;
}

.tac {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-family: Lato Regular;
  font-size: 13px;
}

.bold-tac {
  font-family: Lato Bold;
  color: #000080;
}

.country-select {
  width: 100%;
}

.country-select__control {
  text-align: left;
}

.step4 {
  width: 35%;
  justify-content: flex-start;
  align-items: flex-start;
}

.service1-label {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
}
